import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"


const aboutPage = ({ data, location }) => {
    return (
        <Layout location={location}>
        <SEO title="Personal Projects" />
        <Nav />
        <div>
            <h1>Personal Projects</h1>
            
            <h2>Live <span role="img" aria-label="Grinning Face with Smiling Eyes">😁</span></h2>

            <a href="https://recruitin.net/" target="_blank" rel="noreferrer"> <h3>Recruit'em</h3></a>
            <p>Easily use Google to search profiles on professional networks. Also optimise job ads by using the 'Job Ad Optimiser' to suggest skills and synonyms.</p>
            
            <a href="https://searchlatte.com/" target="_blank" rel="noreferrer"> <h3>Search Latte</h3></a>
            <p>Build Google searches in any combination of Google or Yandex supported top level domain, country and language. A tool for International SEOs.</p>

            <a href="https://mae-game.netlify.app/game.html" target="_blank" rel="noreferrer"> <h3>Mae's Game</h3></a>
            <p>A fun scrabble agains the clock game created by my wife that I digitised using vanilla JavaScript.</p>



            <h2>Retired <span role="img" aria-label="Dizzy Face">😵</span></h2>

            <h3>Mustard Threads</h3>
            <p>A Stack Overflow-like style advice social network. Users posted their pictures and other users would help them with their style.</p>

            <h3>Piggybanker</h3>
            <p>A native Facebook financial comparison application and affiliate site. It turned out that most people don't want their personal debts posted on Facebook <span role="img" aria-label="Man Shrugging">🤷‍♂️</span>. The affiliate site worked pretty well though.</p>            
               
        </div>
      </Layout>
    )
}


export default aboutPage